<template>
  <div class="home">
    <div class="d-flex justify-content-center">
      <div class="text-white div-wrap w-100">
        <div class="div-special p-left mb-4">
          <p>Back in about 2007 when there was a Torn mIRC server, we created an IRC bot to run various Torn-related functions in our IRC channel. It was a hobby that we found both fun and rewarding. We really enjoyed bringing together people from different Torn communities and providing useful game tools for them.</p>
        </div>
        
        <div class="div-special p-right mb-4">
          <p>In fact, we were so passionate about it that when Torn shut down their mIRC server, we immediately began learning new development languages and coding skills in order to now create a Discord bot and continue what we had before. Thus was Prometheus bot born in 2018.</p>
        </div>
        
        <div class="div-special p-left mb-4">
          <p>We built Prometheus from scratch to provide useful game functions as well as fun stuff like giveaways and games for Torn players who use Discord. On this modern platform, there are many possibilities for expanding a bot’s capabilities, and so Prom is constantly learning and growing. The influx of Torn players into our community has meant more contributors to Prom’s advancement. He is now far smarter – and more handsome! – than when he was born.</p>
        </div>
        
        <div class="div-special p-right mb-5">
          <p>Prom is our pride and joy. He has brought together and served more Torn players than we ever imagined. Our passion to continue improving the Torn experience for our community has not abated. If anything, the fact that so many of you are here is driving us ever onward and upward, so Prom will be growing even bigger and better!</p>
        </div>

        <p class="p-special">P.S.  No, we will not sell or rent Prom to you. Quit asking!</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
    
  },
};
</script>

<style scoped>
.div-special {
  width: 65%;
  background-color: rgba(39, 42, 45, 1);
  box-shadow: 0 0 3px 3px rgba(39, 42, 45, 0.3);
  opacity: 0;
  border: 1px solid transparent;
}
.div-special > p {
  padding: 15px;
  text-align: center;
  width: 70%;
  min-width: 600px;
  max-width: 800px;
  margin: 0;
}
.p-special {
  width: 100%;
  background-color: rgba(39, 42, 45, 1);
  box-shadow: 0 0 3px 3px rgba(39, 42, 45, 0.3);
  animation: fadeBottom 0.5s ease-in 1s forwards, borderFlash 0.5s ease-in-out 2s forwards;
  opacity: 0;
  margin: 0;
  border: 1px solid transparent;
}

.p-left {
  margin-right: auto;
}
.p-left p {
  margin-left: auto;
}
.p-right {
  margin-left: auto;
}
.p-right p {
  margin-right: auto;
}
.div-wrap div:nth-child(1) {
  animation: fadeLeft 1s ease-in forwards, borderFlash 0.5s ease-in-out 1s forwards;
}
.div-wrap div:nth-child(2) {
  animation: fadeRight 1s ease-in 0.25s forwards, borderFlash 0.5s ease-in-out 1.25s forwards;
}
.div-wrap div:nth-child(3) {
  animation: fadeLeft 1s ease-in .5s forwards, borderFlash 0.5s ease-in-out 1.5s forwards;
}
.div-wrap div:nth-child(4) {
  animation: fadeRight 1s ease-in .75s forwards, borderFlash 0.5s ease-in-out 1.75s forwards;
}

@keyframes fadeLeft {
  0%   {
    opacity: 0;
    transform: translateX(-300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeRight {
  0%   {
    opacity: 0;
    transform: translateX(300px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeBottom {
  0%   {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes borderFlash {
  0%   {
    border: 1px solid transparent;
  }
  25%   {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  50%   {
    border: 1px solid rgba(255, 255, 255, 1);
  }
  75%   {
    border: 1px solid rgba(255, 255, 255, 0.5);
  }
  100% {
    border: 1px solid transparent;
  }
}

@media (max-width: 992px) {
  .div-special {
    width: 100%;
  }
  .div-special p {
    padding: 15px;
    min-width: initial;
    max-width: initial;
    text-align: center;
  }
  .p-special {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
  }
  
  .p-left {
    margin-right: initial;
  }
  .p-left p {
    width: 90%;
    margin: 0 auto;
  }

  .p-right {
    margin-left: initial;
  }
  .p-right p {
    width: 90%;
    margin: 0 auto;
  }
}
</style>