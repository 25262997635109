export default {
  defaultBackground: "NPCs/backgroundTabDefault.png",
  defaultBackgroundLink: "https://discord.gg/ARkSGh97Wb",
  LLSVGs: [
    "NPCs/Hospital.svg",
    "NPCs/LL1.png",
    "NPCs/LL2.png",
    "NPCs/LL3.png",
    "NPCs/LL4.png",
    "NPCs/LL5.png",
    "NPCs/Inactive.svg", // -1
    "NPCs/Unknown.svg", // -2
  ],
  NPCs: [
    {
      name: "Duke",
      path: "duke",
      tornID: "4",
      HP: undefined,
      hospTimer: 0,
      LL: -2,
      LLTimes: ["-", "-", "-", "-", "-"],
      LLTimesLocal: ["-", "-", "-", "-", "-"],
      LLTimesTS: [],
      LLTimers: ["0", "0", "0", "0", "0"],
      active: true,
      desc: "<br><br>I'm Duke and I'm inactive right now!<br><br>Better luck next time!",
      backgroundImage: "",
      image: "NPCs/Duke.png",
      items: [
        {id: 841, name: "Classic Fedora"},
        {id: 843, name: "Duster"},
        {id: 838, name: "Homemade Pocket Shotgun"},
        {id: 839, name: "Madball"},
        {id: 840, name: "Nail Bomb"},
        {id: 842, name: "Pinstripe Suit Trousers"},
        {id: 837, name: "Rheinmetall MG 3"},
      ], // https://www.torn.com/images/items/${itemID}/large.png - https://www.torn.com/imarket.php#/p=shop&step=shop&type=&searchname=Classic+Fedora
    },
    {
      name: "Leslie",
      path: "leslie",
      tornID: "15",
      HP: undefined,
      hospTimer: 0,
      LL: -2,
      LLTimes: ["-", "-", "-", "-", "-"],
      LLTimesLocal: ["-", "-", "-", "-", "-"],
      LLTimesTS: [],
      LLTimers: ["0", "0", "0", "0", "0"],
      active: true,
      desc: "<br><br>I'm Leslie and I'm inactive right now!<br><br>Better luck next time!",
      backgroundImage: "",
      image: "NPCs/Leslie.png",
      items: [
        {id: 831, name: "Beretta Pico"},
        {id: 836, name: "Black Oxfords"},
        {id: 830, name: "Nock Gun"},
        {id: 832, name: "Riding Crop"},
        {id: 833, name: "Sand"},
        {id: 835, name: "String Vest"},
        {id: 834, name: "Sweatpants"},
      ],
    },
    {
      name: "Jimmy",
      path: "jimmy",
      tornID: "19",
      HP: undefined,
      hospTimer: 0,
      LL: -2,
      LLTimes: ["-", "-", "-", "-", "-"],
      LLTimesLocal: ["-", "-", "-", "-", "-"],
      LLTimesTS: [],
      LLTimers: ["0", "0", "0", "0", "0"],
      active: true,
      desc: "<br><br>I'm Jimmy and I'm inactive right now!<br><br>Better luck next time!",
      backgroundImage: "",
      image: "NPCs/Jimmy.png",
      items: [
        {id: 587, name: "Bag of Sherbet"},
        {id: 1053, name: "Bread Knife"},
        {id: 1042, name: "Concussion Grenade"},
        {id: 586, name: "Jawbreaker"},
        {id: 368, name: "Lawyer Business Card"},
        {id: 151, name: "Pixie Sticks"},
        {id: 982, name: "Ripped Jeans"},
      ],
    },
    {
      name: "Fernando",
      path: "fernando",
      tornID: "20",
      HP: undefined,
      hospTimer: 0,
      LL: -2,
      LLTimes: ["-", "-", "-", "-", "-"],
      LLTimesLocal: ["-", "-", "-", "-", "-"],
      LLTimesTS: [],
      LLTimers: ["0", "0", "0", "0", "0"],
      active: true,
      desc: "<br><br>I'm Fernando and I'm inactive right now!<br><br>Better luck next time!",
      backgroundImage: "",
      image: "NPCs/Fernando.png",
      items: [
        {id: 983, name: "Bandit Mask"},
        {id: 1055, name: "Poison Umbrella"},
        {id: 367, name: "Feathery Hotel Coupon"}, // FHC
      ], // Probably more
    },
    {
      name: "Tiny",
      path: "tiny",
      tornID: "21",
      HP: undefined,
      hospTimer: 0,
      LL: -2,
      LLTimes: ["-", "-", "-", "-", "-"],
      LLTimesLocal: ["-", "-", "-", "-", "-"],
      LLTimesTS: [],
      LLTimers: ["0", "0", "0", "0", "0"],
      active: false,
      desc: "<br><br>I'm Tiny and I'm inactive right now!<br><br>Try attacking me later!",
      backgroundImage: "",
      image: "NPCs/Tiny.jpg",
      items: [
        {id: 850, name: "Sledgehammer"},
        {id: 331, name: "Dumbbells"},
        {id: 818, name: "Six Pack of Energy Drink"},
        {id: 530, name: "Can of Munster"},
        {id: 532, name: "Can of Red Cow"},
        {id: 533, name: "Can of Taurine Elite"},
        {id: 330, name: "Boxing Gloves"}
      ],
    },
    {
      name: "Easter Bunny",
      path: "easter-bunny",
      tornID: "17",
      HP: undefined,
      hospTimer: 0,
      LL: -2,
      LLTimes: ["-", "-", "-", "-", "-"],
      LLTimesLocal: ["-", "-", "-", "-", "-"],
      LLTimesTS: [],
      LLTimers: ["0", "0", "0", "0", "0"],
      active: true,
      desc: "<br><br>I'm Easter Bunny and I'm inactive right now!<br><br>Better luck next Easter!",
      backgroundImage: "",
      image: "NPCs/EasterBunny.jpg",
      items: [
        {id: 1151, name: "Bunny Nose"},
        {id: 101, name: "Bunny Suit"},
        {id: 100, name: "Egg Propelled Launcher"},
        {id: 439, name: "Frying Pan"},
        {id: 588, name: "Goodie Bag"},
        {id: 424, name: "Rabbit Foot"},
        {id: 1150, name: "Ski Mask"},
      ],
    },
    {
      name: "Scrooge",
      path: "scrooge",
      tornID: "10",
      HP: undefined,
      hospTimer: 0,
      LL: -2,
      LLTimes: ["-", "-", "-", "-", "-"],
      LLTimesLocal: ["-", "-", "-", "-", "-"],
      LLTimesTS: [],
      LLTimers: ["0", "0", "0", "0", "0"],
      active: false,
      desc: "<br><br>I'm Scrooge and I'm inactive right now!<br><br>Try attacking me next Christmas!",
      backgroundImage: "",
      image: "NPCs/Scrooge.png",
      items: [
        {id: 605, name: "Diamond Icicle"},
        {id: 729, name: "Scrooge's Boots"},
        {id: 730, name: "Scrooge's Gloves"},
        {id: 726, name: "Scrooge's Top Hat"},
        {id: 727, name: "Scrooge's Topcoat"},
        {id: 728, name: "Scrooge's Trousers"},
        {id: 76, name: "Snow Cannon"},
        {id: 611, name: "Snowball"},
      ]
    }
  ],
  defaultNPCnotif: {
    "4":  [false,false,false,false,false,false],
    "10": [false,false,false,false,false,false],
    "15": [false,false,false,false,false,false],
    "17": [false,false,false,false,false,false],
    "19": [false,false,false,false,false,false],
    "20": [false,false,false,false,false,false],
    "21": [false,false,false,false,false,false]
  },
};
