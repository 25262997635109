<template>
  <div class="d-flex flex-column min-vh-100" id="app" :class="darkMode ? 'body-dark' : 'body-light'">
    <div v-if="error" class="d-flex justify-content-center align-items-center errorMessage">
        {{ error }}
    </div>

    <div class="wrapper">
      <img
        class="img-fluid mt-2"
        style="margin-bottom: -45px"
        :src="require(`@/assets/logo.png`)"
      />
    </div>
    <div class="container-fluid text-center">
      <div class="row justify-content-center">
        <span class="pl-5 pr-5 nextLL darkModeBG darkModeText" @click="openNextLLnpc" v-if="lowestTimer.npcName != undefined">Up next is {{ lowestTimer.npcName }}: {{ lowestTimer.timeStr }}</span>
        <div class="mt-4 w-100" v-if="lowestTimer.npcName == undefined"></div>
      </div>
    </div>
    <Navbar />
    <router-view />

    <div class="wrapper flex-grow-1"></div>

    <!-- Footer -->
    <footer class="page-footer font-small table-dark bg-darker mt-3 justify-content-center container-fluid">
      <!-- Copyright -->
      <div class="row">
        <div class="col-md-4">

        </div>

        <div class="col-md-4 footer-copyright text-center py-3 d-inline-block">
          © 2024 - Made by: <a href="https://www.torn.com/profiles.php?XID=2310495" class="text-white" target="_blank">EddyCro</a><br/>
          Owner: <a href="https://www.torn.com/profiles.php?XID=483525" class="text-white" target="_blank">-R3x-</a>
        </div>

        <!--div class="col-md-4 m-auto">
          <div id="darkModeButton">
            Dark mode
            <label class="switch mr-1">
              <input  type="checkbox" :checked="darkMode" v-model="darkMode">
              <span class="slider round"></span>
            </label>
          </div>
        </div-->
      </div>

      <!-- Copyright -->
    </footer>
    <!-- Footer -->
  </div>
</template>



<script>


import Navbar from "@/components/Navbar.vue";
import { mapMutations } from 'vuex'

export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {
      autoRefreshInterval: undefined,
      apiDataInterval: undefined,
      error: undefined
    }
  },
  created() {
    this.getApiData();
    
    this.updateNPCItems();

    this.autoRefreshInterval = setInterval(() => {
      this.updateNPCCountdown();
    }, 1000);
    this.apiDataInterval = setInterval(() => {
      this.getApiData();
    }, 10000);
  },
  destroyed() {
    if (this.autoRefreshInterval) {
      clearInterval(this.autoRefreshInterval);
    }
    if (this.apiDataInterval) {
      clearInterval(this.apiDataInterval);
    }
  },
  methods: {
    ...mapMutations([
      'changeDarkMode',
      'updateNPCs',
      'updateNPCTimers',
      'updateNPCCountdown',
      'updateNPCItems',
    ]),
    getApiData() {
      fetch("https://prombot.co.uk:8443/api/npc", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.json())
      .then((data) => {
        this.updateNPCs(data);

        this.updateNPCTimers();
        this.error = undefined;
        //this.error = "Couldn't get data from backend server! Trying again soon!"
      })
      .catch((err) => {
        this.error = "Connection lost! Trying again soon!"
        console.log(err.message);
      });
    },
    openNextLLnpc() { 
      window.open(`https://www.torn.com/loader.php?sid=attack&user2ID=${this.lowestTimer.npcID}`, "_blank");
    }
  },
  computed: {
    darkMode: {
      get () {
        return this.$store.state.darkMode
      },
      set (value) {
        this.changeDarkMode(value);
      }
    },
    lowestTimer: {
      get () {
        return this.$store.state.lowestTimer
      }
    },
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

#app {
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
* {
  margin: 0;
  padding: 0;
}
html {
  scrollbar-color: #666 #201c29;
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

/* Small screens */
@media (max-width: 768px) { 
  html {
    overflow-x: initial;
    margin-right: 0;
  }
}
/*
  background: linear-gradient(
    90deg,
    rgba(40, 40, 40, 1) 5%,
    rgba(95, 95, 95, 1) 40%,
    rgba(95, 95, 95, 1) 60%,
    rgba(40, 40, 40, 1) 95%
  );
*/
body {
  color: white;
  height: 100%;
}
.body-light {
  background-image: linear-gradient(216deg, rgba(77, 77, 77,0.05) 0%, rgba(77, 77, 77,0.05) 25%,rgba(42, 42, 42,0.05) 25%, rgba(42, 42, 42,0.05) 38%,rgba(223, 223, 223,0.05) 38%, rgba(223, 223, 223,0.05) 75%,rgba(36, 36, 36,0.05) 75%, rgba(36, 36, 36,0.05) 100%),linear-gradient(44deg, rgba(128, 128, 128,0.05) 0%, rgba(128, 128, 128,0.05) 34%,rgba(212, 212, 212,0.05) 34%, rgba(212, 212, 212,0.05) 57%,rgba(25, 25, 25,0.05) 57%, rgba(25, 25, 25,0.05) 89%,rgba(135, 135, 135,0.05) 89%, rgba(135, 135, 135,0.05) 100%),linear-gradient(241deg, rgba(55, 55, 55,0.05) 0%, rgba(55, 55, 55,0.05) 14%,rgba(209, 209, 209,0.05) 14%, rgba(209, 209, 209,0.05) 60%,rgba(245, 245, 245,0.05) 60%, rgba(245, 245, 245,0.05) 69%,rgba(164, 164, 164,0.05) 69%, rgba(164, 164, 164,0.05) 100%),linear-gradient(249deg, rgba(248, 248, 248,0.05) 0%, rgba(248, 248, 248,0.05) 32%,rgba(148, 148, 148,0.05) 32%, rgba(148, 148, 148,0.05) 35%,rgba(202, 202, 202,0.05) 35%, rgba(202, 202, 202,0.05) 51%,rgba(181, 181, 181,0.05) 51%, rgba(181, 181, 181,0.05) 100%),linear-gradient(92deg, rgb(183,183,183),rgb(183,183,183));
}
.body-dark {
  background-image: linear-gradient(216deg, rgba(77, 77, 77,0.05) 0%, rgba(77, 77, 77,0.05) 25%,rgba(42, 42, 42,0.05) 25%, rgba(42, 42, 42,0.05) 38%,rgba(223, 223, 223,0.05) 38%, rgba(223, 223, 223,0.05) 75%,rgba(36, 36, 36,0.05) 75%, rgba(36, 36, 36,0.05) 100%),linear-gradient(44deg, rgba(128, 128, 128,0.05) 0%, rgba(128, 128, 128,0.05) 34%,rgba(212, 212, 212,0.05) 34%, rgba(212, 212, 212,0.05) 57%,rgba(25, 25, 25,0.05) 57%, rgba(25, 25, 25,0.05) 89%,rgba(135, 135, 135,0.05) 89%, rgba(135, 135, 135,0.05) 100%),linear-gradient(241deg, rgba(55, 55, 55,0.05) 0%, rgba(55, 55, 55,0.05) 14%,rgba(209, 209, 209,0.05) 14%, rgba(209, 209, 209,0.05) 60%,rgba(245, 245, 245,0.05) 60%, rgba(245, 245, 245,0.05) 69%,rgba(164, 164, 164,0.05) 69%, rgba(164, 164, 164,0.05) 100%),linear-gradient(249deg, rgba(248, 248, 248,0.05) 0%, rgba(248, 248, 248,0.05) 32%,rgba(148, 148, 148,0.05) 32%, rgba(148, 148, 148,0.05) 35%,rgba(202, 202, 202,0.05) 35%, rgba(202, 202, 202,0.05) 51%,rgba(181, 181, 181,0.05) 51%, rgba(181, 181, 181,0.05) 100%),linear-gradient(92deg, rgba(28,28,28, 0.9),rgba(28,28,28, 0.9));
}
h1 {
  color: white;
}
.bg-darker {
  background-color: #272a2d !important;
}
a:hover {
  color: #1f85de;
}
a {
  color: white;
}
::-webkit-scrollbar {
  width: 15px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #b1b1b1;
  border-left: 1px solid white;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #646464;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #343a40;
}
.row-no-pm {
  margin: 0;
  padding: 0;
}
.nextLL {
  clip-path: polygon(15% 0%, 85% 0%, 100% 100%, 0% 100%);
  border-bottom: 1px solid rgb(112, 112, 112);
  cursor: pointer;
}
.text-black {
  color: black !important;
}


/* Toggle Button */
/*The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
}
/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}
.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slider {
    background-color: #1F85DE;
}
input:focus + .slider {
    box-shadow: 0 0 1px #1F85DE;
}
input:checked + .slider:before {
    -webkit-transform: translateX(25px);
    -ms-transform: translateX(25px);
    transform: translateX(25px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 25px;
}
.slider.round:before {
    border-radius: 50%;
} 
/* Dark mode stuff */
.darkModeBG, .darkModeBG > * {
  background-color: #272a2d;
}
.darkModeText, .darkModeText > * {
  color: rgb(223, 223, 223);
}
.darkModeBG .tabButton {
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.1);
  color: rgb(223, 223, 223);
}
.darkModeBG .tabButton:hover {
  box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.3);
}
.lightModeBG, .lightModeBG > * {
  background-color: #ffffff;
}
.lightModeText, .lightModeText > * {
  color: rgb(0, 0, 0);
}
.lightModeText, .lightModeText > a {
  color: #1F85DE;
}

/* NOTES */ 
.infoNote {
    background-color: #e7f3fea1;
    border-left: 6px solid #2196F3;
}
.warningNote {
  background-color: #ffffcca1;
  border-left: 6px solid #ffeb3b;
}
.dangerNote {
  background-color: #ffdddda1;
  border-left: 6px solid #f44336;
}
.successNote {
  background-color: #ddffdda1;
  border-left: 6px solid #04AA6D;
}

.divNote {
    margin-bottom: 5px;
    padding: 4px 12px;
}
.divNote p {
    margin-bottom: 1em;
    margin-top: 1em;
}

.errorMessage {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  background: rgba(255, 0, 0, 0.75);
  height: 2rem;
  color: white;
  animation: slideDown 0.5s both;
}

@keyframes slideDown {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.row-no-pm {
  margin: 0;
  padding: 0;
}
.row-no-m {
  margin: 0;
}
.col-no-padding {
  padding: 0;
}
</style>