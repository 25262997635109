<template>
  <div id="nav">
    <nav class="navbar navbar-expand-lg navbar-dark bg-darker d-print mb-3 p-0">
      <div class="container col-9 col-md-12">
        <button
          class="navbar-toggler ml-auto"
          style="border-radius: 0"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggler"
          aria-controls="navbarToggler"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse mt-0 mb-0" id="navbarToggler">
          <ul class="navbar-nav m-auto">
            <li class="nav-item">
              <router-link
                class="nav-link border-md-left border-md-right nav-linkDD"
                to="/home"
                >HOME</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link border-md-right nav-linkDD"
                to="/npc-loot"
                >NPCs</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link border-md-right nav-linkDD"
                to="/travel"
                >TRAVEL</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link border-md-right nav-linkDD"
                to="/commands"
                >COMMANDS</router-link
              >
            </li>
            <li class="nav-item">
              <router-link
                class="nav-link border-md-right nav-linkDD"
                to="/join"
                >DISCORD</router-link
              >
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
/* eslint-disable */

export default {
  name: "Navbar",
  computed: {
    darkMode: {
      get () {
        return this.$store.state.darkMode
      }
    }
  },
};
</script>

<style scoped>
.nav-item {
  width: 150px;
  margin: 0 auto;
  font-weight: bold;
}
.nav-linkDD:after {
  display: block;
  content: "";
  border-bottom: 2px solid #1f85de;
  transform: scaleX(0);
  transition: transform 0.1s ease-in-out;
}
.nav-linkDD:hover:after {
  transform: scaleX(1);
}
.navbar-toggler {
  border: 0px;
}
.border-md-right {
  border-right: 1px solid rgb(112, 112, 112);
}
.border-md-left {
  border-left: 1px solid rgb(112, 112, 112);
}
@media (max-width: 992px) {
  .border-md-right {
    border-right: none;
  }
  .border-md-left {
    border-left: none;
  }
}

.router-link-active {
  color: white !important;
}
</style>
