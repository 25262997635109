/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import VueNativeNotification from 'vue-native-notification';

import App from "./App.vue";
import router from "./router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment";

import localStore from "@/store.js";

Vue.config.productionTip = false;

Vue.use(Vuex);

function getParsedNotifs() {
  let parsed = {};
  try {
    let localNPCnotif = JSON.parse(localStorage.NPCnotif)
    Object.keys(localStore.defaultNPCnotif).forEach(key => {
      if (localNPCnotif[key] == undefined) parsed[key] = localStore.defaultNPCnotif[key];
      else parsed[key] = localNPCnotif[key];
    });
    localStorage.NPCnotif = JSON.stringify(parsed);
  } catch (error) {
    parsed = localStore.defaultNPCnotif;
    localStorage.NPCnotif = JSON.stringify(parsed);
  }
  return parsed;
}

function saveDefaultNotifs() {
  localStorage.NPCnotif = JSON.stringify(localStore.defaultNPCnotif);
  return localStore.defaultNPCnotif
}

let notificationAudio = new Audio(require(`@/assets/audio/pop-up-alert-notification.wav`));
notificationAudio.volume = 0.1;

const store = new Vuex.Store({
  state: {
    darkMode: localStorage.darkMode ? JSON.parse(localStorage.darkMode) : true,
    npcData: localStore.NPCs,
    lastUpdate: undefined,
    lowestTimer: { npcID: undefined, npcName: undefined, npcLL: undefined, TS: Infinity,  timeStr: '' },
    NPCnotifications: localStorage.NPCnotif ? getParsedNotifs() : saveDefaultNotifs(),
  },
  mutations: {
    changeDarkMode(state, value) {
      state.darkMode = value;
      localStorage.darkMode = value;
    }, 
    changeNotifValue(state, value) {
      if (value.npcID != undefined) {
        state.NPCnotifications[value.npcID] = value.array;
        localStorage.NPCnotif = JSON.stringify(state.NPCnotifications);
      }
    },
    updateNPCs(state, value) {
      state.npcData.forEach(npc => {
        npc.hospTimer = value.timers[npc.tornID].TS;
        npc.LL = value.timers[npc.tornID].LL;

        npc.HP = { ...value.timers[npc.tornID].HP }

        // NPC being attacked
        if (npc.LL > 1 && value.timers[npc.tornID].HP.current < (value.timers[npc.tornID].HP.max * 0.99)) { 
          if(npc.beingAttacked != true && state.NPCnotifications[npc.tornID][5]) notifyAttacked(npc);
          npc.beingAttacked = true;
        } else {
          npc.beingAttacked = false;
        }

      });
      state.lastUpdate = moment(value.lastUpdate).utc().format('DD.MM.YYYY, HH:mm:ss');
    },
    updateNPCTimers(state) {
      //let currentTime = moment().utc(); // .format('DD.MM.YYYY, HH:mm:ss')

      state.npcData.forEach((tab) => {
        if (tab.hospTimer > 0) {
          tab.LLTimesTS = [
            tab.hospTimer * 1000,
            (tab.hospTimer + 30 * 60) * 1000,
            (tab.hospTimer + 90 * 60) * 1000,
            (tab.hospTimer + 210 * 60) * 1000,
            (tab.hospTimer + 450 * 60) * 1000,
          ];
          tab.LLTimes = [
            moment(tab.LLTimesTS[0]).utc().format("HH:mm:ss") + " TCT",
            moment(tab.LLTimesTS[1]).utc().format("HH:mm:ss") + " TCT",
            moment(tab.LLTimesTS[2]).utc().format("HH:mm:ss") + " TCT",
            moment(tab.LLTimesTS[3]).utc().format("HH:mm:ss") + " TCT",
            moment(tab.LLTimesTS[4]).utc().format("HH:mm:ss") + " TCT",
          ];
          /*tab.LLTimesLocal = [
            moment(tab.LLTimesTS[0]).format("HH:mm:ss") + " local",
            moment(tab.LLTimesTS[1]).format("HH:mm:ss") + " local",
            moment(tab.LLTimesTS[2]).format("HH:mm:ss") + " local",
            moment(tab.LLTimesTS[3]).format("HH:mm:ss") + " local",
            moment(tab.LLTimesTS[4]).format("HH:mm:ss") + " local",
          ];

          let distances = [
            moment(tab.LLTimesTS[0]).utc() - currentTime,
            moment(tab.LLTimesTS[1]).utc() - currentTime,
            moment(tab.LLTimesTS[2]).utc() - currentTime,
            moment(tab.LLTimesTS[3]).utc() - currentTime,
            moment(tab.LLTimesTS[4]).utc() - currentTime,
          ];

          tab.LLTimers = [
            distances[0] <= 0 ? "0" : distances[0] < 3600000 ? `in ${moment(distances[0]).utc().format('mm:ss')}` : `in ${moment(distances[0]).utc().format('HH:mm:ss')}`,
            distances[1] <= 0 ? "0" : distances[1] < 3600000 ? `in ${moment(distances[1]).utc().format('mm:ss')}` : `in ${moment(distances[1]).utc().format('HH:mm:ss')}`,
            distances[2] <= 0 ? "0" : distances[2] < 3600000 ? `in ${moment(distances[2]).utc().format('mm:ss')}` : `in ${moment(distances[2]).utc().format('HH:mm:ss')}`,
            distances[3] <= 0 ? "0" : distances[3] < 3600000 ? `in ${moment(distances[3]).utc().format('mm:ss')}` : `in ${moment(distances[3]).utc().format('HH:mm:ss')}`,
            distances[4] <= 0 ? "0" : distances[4] < 3600000 ? `in ${moment(distances[4]).utc().format('mm:ss')}` : `in ${moment(distances[4]).utc().format('HH:mm:ss')}`,
          ];

          tab.LL = tab.LLTimers.filter((timer) => timer == "0").length;*/
        } else {
          if(tab.hospTimer == -1) tab.LL = -1;
        }
      });
      //state.lowestTimer = lowest;
    }, 
    updateNPCCountdown(state) {
      let currentTime = moment().utc(); // .format('DD.MM.YYYY, HH:mm:ss')

      let lowest = {npcID: undefined, npcName: undefined, npcLL: undefined, TS: Infinity,  timeStr: '' };

      state.npcData.forEach((tab) => {
        if (tab.hospTimer > 0) {
          let distances = [
            moment(tab.LLTimesTS[0]).utc() - currentTime,
            moment(tab.LLTimesTS[1]).utc() - currentTime,
            moment(tab.LLTimesTS[2]).utc() - currentTime,
            moment(tab.LLTimesTS[3]).utc() - currentTime,
            moment(tab.LLTimesTS[4]).utc() - currentTime,
          ];

          let tempLowDistance = [distances[3], distances[4]].filter(d => d > 0)[0];

          tab.LLTimers = [
            distances[0] <= 0 ? "0" : distances[0] < 3600000 ? `in ${moment(distances[0]).utc().format('mm:ss')}` : `in ${moment(distances[0]).utc().format('HH:mm:ss')}`,
            distances[1] <= 0 ? "0" : distances[1] < 3600000 ? `in ${moment(distances[1]).utc().format('mm:ss')}` : `in ${moment(distances[1]).utc().format('HH:mm:ss')}`,
            distances[2] <= 0 ? "0" : distances[2] < 3600000 ? `in ${moment(distances[2]).utc().format('mm:ss')}` : `in ${moment(distances[2]).utc().format('HH:mm:ss')}`,
            distances[3] <= 0 ? "0" : distances[3] < 3600000 ? `in ${moment(distances[3]).utc().format('mm:ss')}` : `in ${moment(distances[3]).utc().format('HH:mm:ss')}`,
            distances[4] <= 0 ? "0" : distances[4] < 3600000 ? `in ${moment(distances[4]).utc().format('mm:ss')}` : `in ${moment(distances[4]).utc().format('HH:mm:ss')}`,
          ];
          
          let oldLL = tab.LL;
          tab.LL = tab.LLTimers.filter((timer) => timer == "0").length;
          if(oldLL < tab.LL) { // Notify for LL up
            if(state.NPCnotifications[tab.tornID][tab.LL - 1]) notifyLLup(tab);
          } else {
            distances.forEach((dist, index) => {
              if(dist < 301000 && dist > 300000) { // DEFAULT IS 301000 and 300000s
                if(state.NPCnotifications[tab.tornID][index]) notify5mins(tab, index);
              }
            })
          }
          
          /*
          console.log(lowest)
          console.log(`NPC: ${tab.name}, tempLow: ${tempLowDistance}`)*/

          if(tempLowDistance != undefined) {
            if(tempLowDistance < lowest.TS) {
              let TSstring = tempLowDistance < 3600000 ? moment(tempLowDistance).utc().format('mm:ss') : moment(tempLowDistance).utc().format('HH:mm:ss');
              lowest = {npcID: tab.tornID, npcName: tab.name, npcLL: tab.LL + 1, TS: tempLowDistance,  timeStr: TSstring };
            }
          }

          let deadTimer = tab.hospTimer * 1000 - 7200000;

          tab.progressBar = [
            parseInt((currentTime - deadTimer) / (tab.LLTimesTS[0] - deadTimer) * 100),
            parseInt((currentTime - deadTimer) / (tab.LLTimesTS[1] - deadTimer) * 100),
            parseInt((currentTime - deadTimer) / (tab.LLTimesTS[2] - deadTimer) * 100),
            parseInt((currentTime - deadTimer) / (tab.LLTimesTS[3] - deadTimer) * 100),
            parseInt((currentTime - deadTimer) / (tab.LLTimesTS[4] - deadTimer) * 100)
          ]

        } else {
          if(tab.hospTimer == -1) tab.LL = -1;
        }
      });
      if(lowest.npcID != -1) state.lowestTimer = lowest;
    }, 
    updateNPCItems(state) {
      // Sort items
      state.npcData.forEach((tab) => {
        tab.items.sort((a, b) => ('' + a.name).localeCompare(b.name))
      });

    },
  }
});

Vue.use(VueNativeNotification, {
  requestOnNotify: false
})


new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

// Initiate notification service worker
//registerNotifWorker();

// FUNCTIONS
async function notifyLLup(tab) {
  notificationAudio.play();

  /*let notifSW = await navigator.serviceWorker.getRegistrations();
  notifSW = notifSW[0];
  console.log(notifSW);
  
  notifSW.showNotification(`SW - ${tab.name} is now at Loot Level ${tab.LL}`, {
    body: 'Click here to open the attack screen.',
    icon: tab.image,
    data: `https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`,
  });*/

  Vue.notification.show(`${tab.name} is now at Loot Level ${tab.LL}`, {
    body: 'Click here to open the attack screen.',
    icon: tab.image,
    data: `https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`,
  }, {
    onclick: function () {
      window.open(`https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`, '_blank');
    }
  })
}

async function notify5mins(tab, index) {
  notificationAudio.play();
  
  /*let notifSW = await navigator.serviceWorker.getRegistrations();
  notifSW = notifSW[0];
  console.log(notifSW);
  
  notifSW.showNotification(`SW - ${tab.name} will soon be at Loot Level ${index + 1}`, {
    body: '5 mins left!\nClick here to open the attack screen.',
    icon: tab.image,
    data: `https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`,
  });*/

  Vue.notification.show(`${tab.name} will soon be at Loot Level ${index + 1}`, {
    body: '5 mins left!\nClick here to open the attack screen.',
    icon: tab.image,
    data: `https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`,
  }, {
    onclick: function () {
      window.open(`https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`, '_blank');
    }
  })
}

async function notifyAttacked(tab) {
  notificationAudio.play();
  
  Vue.notification.show(`${tab.name} is being attacked at Loot Level ${tab.LL}`, {
    body: 'Click here to open the attack screen.',
    icon: tab.image,
    data: `https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`,
  }, {
    onclick: function () {
      window.open(`https://www.torn.com/loader.php?sid=attack&user2ID=${tab.tornID}`, '_blank');
    }
  })
}

/*
async function registerNotifWorker() {
  if (!('serviceWorker' in navigator)) {
    throw new Error('No Service Worker support!')
  }
  if (!('PushManager' in window)) {
    throw new Error('No Push API Support!')
  }

  if(Vue.notification == 'granted') {
    navigator.serviceWorker.register('./notificationSWorker.js').then(function(registration) {
      console.log('Registration succeeded.');
      registration.update();
    }).catch(function(error) {
      console.log('Registration failed with ' + error);
    });
  }
}*/

/**
 * Convert a date to a relative time string, such as
 * "a minute ago", "in 2 hours", "yesterday", "3 months ago", etc.
 * using Intl.RelativeTimeFormat
 */
export function getRelativeTimeString(date, lang = navigator.language) {
  // Allow dates or times to be passed
  const timeMs = typeof date === "number" ? date : date.getTime();

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Array reprsenting one minute, hour, day, week, month, etc in seconds
  const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity];

  // Array equivalent to the above but in the string representation of the units
  const units = ["second", "minute", "hour", "day", "week", "month", "year"];

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex(cutoff => cutoff > Math.abs(deltaSeconds));

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: "auto" });
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}

export function getNumberWithCommas(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);
};