import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

/* eslint-disable */

Vue.use(VueRouter);
const DEFAULT_TITLE = 'Prometheus Bot';
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    props: true,
  },
  {
    path: "/commands",
    name: "Commands",
    meta: { title: 'Prometheus Bot - Commands' },
    component: () =>
      import(/* webpackChunkName: "commands" */ "../views/Commands.vue"),
  },
  {
    path: "/join",
    name: "Join",
    meta: { title: 'Prometheus Bot - Join' },
    component: () =>
      import(/* webpackChunkName: "join" */ "../views/Join.vue"),
  },
  {
    path: "/npc-loot/:npcName?",
    name: "NPCLoot",
    meta: { title: 'Prometheus Bot - NPC Loot' },
    component: () =>
      import(/* webpackChunkName: "npcloot" */ "../views/NPCLoot.vue"),
  },
  {
    path: "/travel/:country?",
    name: "Travel",
    meta: { title: 'Prometheus Bot - Travel' },
    component: () =>
      import(/* webpackChunkName: "travel" */ "../views/Travel.vue"),
  },
  {
    path: "/trade",
    name: "Trade",
    meta: { title: 'Prometheus Bot - Trade' },
    component: () =>
      import(/* webpackChunkName: "trade" */ "../views/Trade.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/home",
  }
];


const router = new VueRouter({
  mode: 'history',
  routes,
});

router.afterEach((to, from) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
      document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
